body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@keyframes blink {
  0% {
    color: black;
  }
  50% {
    color: #ffa000;
  }
  100% {
    color: black;
  }
}
@-webkit-keyframes blink {
  0% {
    color: black;
  }
  50% {
    color: #ffa000;
  }
  100% {
    color: black;
  }
}

@keyframes pulse {
  0% {
    color: rgb(158, 158, 158);
  }
  50% {
    color: white;
  }

  100% {
    color: rgb(158, 158, 158);
  }
}

@-webkit-keyframes pulse {
  0% {
    color: rgb(158, 158, 158);
  }
  50% {
    color: white;
  }

  100% {
    color: rgb(158, 158, 158);
  }
}

@keyframes pulse-alarm {
  0% {
    color: rgb(244, 67, 54);
  }
  50% {
    color: white;
  }

  100% {
    color: rgb(244, 67, 54);
  }
}

@-webkit-keyframes pulse-alarm {
  0% {
    color: rgb(244, 67, 54);
  }
  50% {
    color: white;
  }

  100% {
    color: rgb(244, 67, 54);
  }
}

@keyframes pulse-bold {
  0% {
    font-weight: bold;
  }
  50% {
    font-weight: normal;
  }

  100% {
    font-weight: bold;
  }
}

@-webkit-keyframes pulse-bold {
  0% {
    font-weight: bold;
  }
  25% {
    font-weight: normal;
  }

  50% {
    font-weight: bold;
  }
  100% {
    font-weight: normal;
  }
}

.pulse-alarm {
  -webkit-animation: pulse-alarm 3s;
  -moz-animation: pulse-alarm 3s;
  animation: pulse-alarm 3s;
}

.people-icon {
  -webkit-animation: blink 10s infinite;
  -moz-animation: blink 10s infinite;
  animation: blink 10s infinite;
}

.people-icon svg {
  width: 40px;
  height: 40px;
}

.datagrid-card-row td:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.datagrid-card-row td:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.events-enter {
  opacity: 0;
}
.events-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.events-exit {
  opacity: 1;
}
.events-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.leaflet-div-icon {
  border: 0 !important;
  background: none !important;
}

.poi-selected {
  background-color: "yellow";
}
